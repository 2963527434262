import { useRef, useState } from "react"
import styled from "styled-components"

import { IconButton, Tooltip } from "@material-ui/core"

import CopyIcon from "src/ui/icons/copy.svg"
import { spacing } from "src/ui/spacing"

export function CopyText({
  value,
  displayText,
  width = 24,
  copiedText,
}: {
  value: string
  displayText?: React.ReactNode
  width?: number
  copiedText: NonNullable<React.ReactNode>
}) {
  const [hasCopied, setHasCopied] = useState(false)
  const timerRef = useRef<NodeJS.Timer | null>(null)
  const anchorRef = useRef<HTMLButtonElement | null>(null)

  async function handleCopy() {
    await navigator.clipboard.writeText(value)
    setHasCopied(true)

    if (!timerRef.current) {
      timerRef.current = setTimeout(() => {
        setHasCopied(false)
        timerRef.current = null
      }, 2000)
    }
  }

  return (
    <Tooltip
      title={copiedText}
      open={hasCopied}
      PopperProps={{ anchorEl: anchorRef.current }}
    >
      <StyledBox>
        {displayText}
        <IconButton size="small" ref={anchorRef} onClick={handleCopy}>
          <CopyIcon width={width} height={width} />
        </IconButton>
      </StyledBox>
    </Tooltip>
  )
}

const StyledBox = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${spacing.XS};
`
